import React from "react";

export class Text extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: null,
            content: null
        }
    }

    componentDidMount() {
        fetch(`https://www.googleapis.com/blogger/v3/blogs/1121658776827004251/pages/${this.props.pageId}?key=AIzaSyBm7KIjfVCs1NOpoUaJDB7xOoGTIsoYl28`)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    title: res.title.replace(/ *\[\[[^)]*\]\] */g, ""),
                    content: res.content
                });
            });
    }

    render() {
        if(this.props.align === "left") {
            return (
                <div className="grid grid-cols-12 p-0 md:p-12 pb-8 md:pb-12">
                    {
                        this.state.title === null ? (
                            <div className="col-span-12 md:col-span-5 relative px-8 mb-8 md:mb-0 animate-pulse">
                                <div className="h-2 bg-slate-200 rounded col-span-2 mb-6"/>
                                <div className="h-2 bg-slate-200 rounded col-span-2 w-[60%] float-right"/>
                            </div>
                        ) : (
                            <h1 className={`col-span-12 md:col-span-5 text-left md:text-right uppercase text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-black text-leonblue relative pt-4 md:pt-0 pr-12 pl-12 md:pl-16 mb-4 md:mb-0`}>{this.state.title}</h1>
                        )
                    }
                    {
                        this.state.content === null ? (
                            <div className="col-span-12 md:col-span-7 px-8 animate-pulse">
                                <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 mb-6"/>
                                <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 mb-6"/>
                                <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 mb-6"/>
                                <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 w-[60%]"/>
                            </div>
                        ) : (
                            <div className="col-span-12 md:col-span-7 px-12 md:px-8 page-content" dangerouslySetInnerHTML={{__html: this.state.content }}/>
                        )
                    }
                </div>
            );
        } else if(this.props.align === "right") {
            return (
                <div className="grid grid-cols-12 p-0 md:p-12 pb-8 md:pb-12">
                    {
                        this.state.content === null ? (
                            <div className="col-span-12 md:col-span-7 px-8 animate-pulse order-last md:order-first">
                                <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 mb-6"/>
                                <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 mb-6"/>
                                <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 mb-6"/>
                                <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 w-[60%]"/>
                            </div>
                        ) : (
                            <div className="col-span-12 md:col-span-7 px-12 md:px-8 page-content order-last md:order-first" dangerouslySetInnerHTML={{__html: this.state.content }}/>
                        )
                    }
                    {
                        this.state.title === null ? (
                            <div className="col-span-12 md:col-span-5 relative px-8 mb-8 md:mb-0 animate-pulse order-first md:order-last">
                                <div className="h-2 bg-slate-200 rounded col-span-2 mb-6"/>
                                <div className="h-2 bg-slate-200 rounded col-span-2 w-[60%]"/>
                            </div>
                        ) : (
                            <h1 className={`col-span-12 md:col-span-5 uppercase text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-black text-leonblue relative pt-4 md:pt-0 pl-12 pr-12 md:pr-16 mb-4 md:mb-0 order-first md:order-last ${ this.state.title !== "" ? "block" : "hidden" }`}>{this.state.title}</h1>
                        )
                    }
                </div>
            );
        } else {
            return(
                <div className={`grid grid-cols-12 p-0 md:p-12 pb-8 md:pb-12 ${this.props.marginTop === 0 ? "!pt-1" : ""}`}>
                    {
                        this.state.content === null ? (
                            <div className="col-span-12 px-8 animate-pulse">
                                <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 mb-6"/>
                                <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 mb-6"/>
                                <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 mb-6"/>
                                <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 w-[60%]"/>
                            </div>
                        ) : (
                            <div className={`col-span-12 px-12 md:px-8 page-content img-center ${this.props.align === "center" ? "text-center" : ""}`} dangerouslySetInnerHTML={{__html: this.state.content }}/>
                        )
                    }
                </div>
            );
        }
    }
}

export default Text;