import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Helmet } from "react-helmet";

import Navigation from "./components/Navigation";
import Index from "./routes/Index";
import HelendeAdem from "./routes/HelendeAdem";
import PositieveBijwerkingen from "./routes/PositieveBijwerkingen";
import Ademsessies from "./routes/Ademsessies";
import Locatie from "./routes/Locatie";
import OverLeon from "./routes/OverLeon";
import Blog from "./routes/Blog";
import Ervaringen from "./routes/Ervaringen";
import Contact from "./routes/Contact";
import Post from "./routes/Post";
import Error from "./routes/Error";

import menuImage from "./img/foto_menu.jpg";

const root = ReactDOM.createRoot(document.getElementById('root'));
const pages = [
	{
		page: "index",
		label: "Home",
		hidden: true
	},
	{
		page: "helende-adem",
		label: "Helende adem",
		hidden: false
	},
	{
		page: "positieve-bijwerkingen",
		label: "Positieve bijwerkingen",
		hidden: false
	},
	{
		page: "ademsessies",
		label: "Ademsessies",
		hidden: false
	},
	{
		page: "locatie",
		label: "Locatie"
	},
	{
		page: "over-leon",
		label: "Over Leon",
		hidden: false
	},
	{
		page: "blog",
		label: "Blog",
		hidden: false
	},
	{
		page: "ervaringen",
		label: "Ervaringen",
		hidden: false
	},
	{
		page: "contact",
		label: "Contact",
		hidden: false
	}
];

fetch(`https://www.googleapis.com/blogger/v3/blogs/1121658776827004251/posts?orderBy=published&fetchImages=true&maxResults=500&key=AIzaSyBm7KIjfVCs1NOpoUaJDB7xOoGTIsoYl28`)
	.then(res => res.json())
	.then(res => {

		var posts = res.items.filter((post) => { return (post.labels !== undefined ? ["<ervaring>", "<ervaring-home>"].every(label => !post.labels.includes(label)) : true) && (post.labels !== undefined ? !post.labels.includes("<quote>") : true) });

		root.render(
			<BrowserRouter>
				<Helmet>
					<title>leonadem</title>
					<meta name="description" content="Ervaar de kracht van de helende adem"/>
					<meta property="og:description" content="Ervaar de kracht van de helende adem"/>
					<meta name="twitter:description" content="Ervaar de kracht van de helende adem"/>
				</Helmet>
				<Navigation pages={pages} img={menuImage}/>
				<Routes>
					<Route path="/" element={<Index pages={pages}/>}/>
					<Route path="/helende-adem" element={<HelendeAdem pages={pages}/>}/>
					<Route path="/positieve-bijwerkingen" element={<PositieveBijwerkingen pages={pages}/>}/>
					<Route path="/ademsessies" element={<Ademsessies pages={pages}/>}/>
					<Route path="/locatie" element={<Locatie pages={pages}/>}/>
					<Route path="/over-leon" element={<OverLeon pages={pages}/>}/>
					<Route path="/blog">
						<Route index={true} element={<Blog pages={pages}/>}/>
						{
							posts.map((post, index) => {
								return <Route index={false} key={`post-${index}`} path={`:${encodeURIComponent(post.title.toLowerCase().replaceAll(" ", "-"))}/*`} element={<Post pages={pages} post={post}/>}/>
							})
						}
					</Route>
					<Route path="/ervaringen" element={<Ervaringen pages={pages}/>}/>
					<Route path="/contact" element={<Contact pages={pages}/>}/>
					<Route path="*" element={<Error/>}/>
				</Routes>
			</BrowserRouter>
		);
	});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
