import React from "react";

export class SideEffects extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sideEffects: []
        }
    }

    componentDidMount() {
        fetch(`https://www.googleapis.com/blogger/v3/blogs/1121658776827004251/pages/246355143456090996?key=AIzaSyBm7KIjfVCs1NOpoUaJDB7xOoGTIsoYl28`)
            .then(res => res.json())
            .then(res => {
                var sideEffects = res.content.replaceAll(` style="text-align: left;"`, "").replaceAll("<ul><li>", "").replaceAll("<p>", "").replaceAll("</p>", "").replaceAll(`</li></ul>`, "").split(`</li><li>`);
                this.setState({
                    sideEffects: this.props.amount ? sideEffects.sort(() => Math.random() - Math.random()).slice(0, this.props.amount) : sideEffects
                });
            });
    }

    render() {
        return (
            <div className="relative text-center px-4 pt-4 mt-20">
                <h1 className="uppercase text-3xl md:text-5xl lg:text-6xl xl:text-7xl max-w-4xl mx-auto font-black text-leonblue mb-8">Positieve bijwerkingen</h1>
                {
                    this.state.sideEffects.map((sideEffect, index) => {
                        return (
                            <div key={`side-effect-${index}`} className="px-8">
                                <p className="uppercase font-medium text-lg">{sideEffect}</p>
                                <hr className="mx-auto max-w-3xl border-b-1 border-leonblue my-4"/>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

export default SideEffects;