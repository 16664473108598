import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Jumbotron from "../components/Jumbotron";
import Divider from "../components/Divider";
import Container from "../components/Container";
import Emphasize from "../components/Emphasize";
import Footer from "../components/Footer";
import Text from "../components/Text";
import SideEffects from "../components/SideEffects";
import PositieveBijwerkingenImage from "../img/foto_positievebijwerkingen.jpg";

class PositieveBijwerkingen extends React.Component {
	constructor(props) {
        super(props);

        this.state = {
            pages: this.props.pages
        }
    }

	componentDidMount() {
		window.scrollTo(0, 0);
	}

    render() {
		return (
			<>
				<Helmet>
					<title>leonadem - Positieve Bijwerkingen</title>
					<meta property="og:title" content="leonadem - Positieve Bijwerkingen"/>
					<meta property="og:image" content={new URL(PositieveBijwerkingenImage, document.baseURI).href}/>
					<meta property="og:url" content={window.location.href}/>
					<meta property="twitter:title" content="leonadem - Positieve Bijwerkingen"/>
					<meta property="twitter:image" content={new URL(PositieveBijwerkingenImage, document.baseURI).href}/>
				</Helmet>
				<Jumbotron title="Positieve bijwerkingen" img={PositieveBijwerkingenImage}/>
				<Divider/>
				<Container>
					<Text align="center" pageId="1444016942720554617"/>
				</Container>
				<Emphasize pageId="2821983718792406337"/>
                <SideEffects/>
                <div className="text-center p-8">
                    <Link to="/contact">
                        <button className="transition-all text-lg font-normal bg-leonblue hover:bg-leonslate hover:scale-105 text-white py-3 px-6 mt-0 mb-6 underline underline-offset-2 cursor-pointer w-full md:w-fit">
                            Plan een sessie
                        </button>
                    </Link>
                </div>
				<Footer pages={this.state.pages} currentPage="positieve-bijwerkingen"/>
			</>
		);
	}
}

export default PositieveBijwerkingen;