import React from "react";

import Divider from "../components/Divider";

export class Emphasize extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: null
        }
    }

    componentDidMount() {
        fetch(`https://www.googleapis.com/blogger/v3/blogs/1121658776827004251/pages/${this.props.pageId}?key=AIzaSyBm7KIjfVCs1NOpoUaJDB7xOoGTIsoYl28`)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    content: res.content
                });
            });
    }

    render() {
        return (
            <>
                <div className="bg-leonslate w-full">
                    <div className="max-w-[1300px] mx-auto text-white px-12 md:px-24 pt-16 pb-24 text-center">
                        {
                            this.state.content === null ? (
                                <div className="col-span-12 md:col-span-7 px-8 animate-pulse">
                                    <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 mb-6"/>
                                    <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 mb-6"/>
                                    <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 mb-6"/>
                                    <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 w-[60%]"/>
                                </div>
                            ) : (
                                <span className="text-lg font-thin leading-relaxed" dangerouslySetInnerHTML={{__html: this.state.content }}/>
                            )
                        }
                    </div>
                </div>
                <Divider/>
            </>
        );
    }
}

export default Emphasize;