import React from "react";
import { Link } from "react-router-dom";

import Container from "./Container";
import logoSmall from "../img/logo_small.png";

export class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pages: this.props.pages,
            currentPage: this.props.currentPage
        }
    }

    render() {
        return (
            <>
                <div className="bg-leonslate text-white text-center font-thin">
                    <Container>
                        <p className="py-16 px-8">Schroom niet en neem vrijblijvend <Link to="/contact" className="underline underline-offset-2">contact</Link> met mij op. Ik help je graag!</p>
                    </Container>
                </div>
                <div className="bg-leonslate-dark text-white text-center font-thin">
                    <Container>
                        <Link to="/"><img src={logoSmall} alt="logo_small.png" className="w-28 pt-16 mx-auto"/></Link>
                        <p className="pt-4 pb-12 px-8">06 212 76 820 | info@leonadem.nl</p>
                        <div className="pb-16 px-4">
                            {
                                this.state.pages.map((page, index) => {
                                    return page.hidden ? null : <Link to={`/${page.page}`} key={`footer-link-${index}`} className={`mx-2 inline-block transition-all hover:text-leonblue ${this.state.currentPage === page.page ? "text-leonblue" : ""}`}>{page.label}</Link>
                                })
                            }
                        </div>
                    </Container>
                </div>
            </>
        );
    }
}

export default Footer;