import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Jumbotron from "../components/Jumbotron";
import Divider from "../components/Divider";
import Container from "../components/Container";
import Footer from "../components/Footer";
import BlogImage from "../img/foto_blog.jpg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fab);
library.add(fas);
library.add(far);

class Post extends React.Component {
	constructor(props) {
        super(props);

        this.state = {
            pages: this.props.pages,
            post: this.props.post,
			posts: [],
			labels: [],
			postArchiveAmount: 3
        }
    }

	componentDidMount() {
		window.scrollTo(0, 0);

		fetch(`https://www.googleapis.com/blogger/v3/blogs/1121658776827004251/posts?orderBy=published&maxResults=500&key=AIzaSyBm7KIjfVCs1NOpoUaJDB7xOoGTIsoYl28`)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    posts: res.items.filter((post) => { return post.labels !== undefined ? ["<ervaring>", "<ervaring-home>"].every(label => !post.labels.includes(label)) : true }),
					labels: [...new Set(res.items.map((post) => { return post.labels }).flat())].filter(item => ![undefined, "<ervaring>", "<ervaring-home>", "<quote>"].includes(item))
                });
            });
	}

	static getDerivedStateFromProps(nextProps) {
		window.scrollTo(0, 0);

		return {
			post: nextProps.post
		}
	}

    render() {
		var postArchive = this.state.posts.map((post) => {
			if(post.labels === undefined) {
				return post;
			} else if((!post.labels.includes("<quote>")) && (["<ervaring>", "<ervaring-home>"].every(label => !post.labels.includes(label)))) {
				return post;
			} else {
				return undefined;
			}
		}).filter((post) => post !== undefined);

		return (
			<>
				<Helmet>
					<title>leonadem - {this.state.post.title}</title>
					<meta property="og:title" content={`leonadem - ${this.state.post.title}`}/>
					<meta property="og:image" content={this.state.post.images !== undefined ? this.state.post.images[0].url : new URL(BlogImage, document.baseURI).href}/>
					<meta property="og:url" content={window.location.href}/>
					<meta property="twitter:title" content={`leonadem - ${this.state.post.title}`}/>
					<meta property="twitter:image" content={this.state.post.images !== undefined ? this.state.post.images[0].url : new URL(BlogImage, document.baseURI).href}/>
				</Helmet>
				<Jumbotron title="Blog" img={BlogImage}/>
				<Divider/>
				<Container>
					<div className="mb-24 pt-0 px-0 md:px-20 text-center">
						<div className="grid grid-cols-1 md:grid-cols-3 gap-0 md:gap-8 mb-8 px-4 md:px-0">
							<div className="col-span-2 text-left">
								<div className="bg-white shadow mb-8 p-6 pb-4">
									<h2 className="text-3xl uppercase font-medium mb-2">{this.state.post.title}</h2>
									<div className="page-content img-full" dangerouslySetInnerHTML={{__html: this.state.post.content }}/>
									<p className="text-xs mt-8 mb-4">Geschreven op {new Date(this.state.post.published).toLocaleDateString('nl-NL', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
									{
										this.state.post.labels !== undefined && this.state.post.labels.map((label, index) => {
											return <Link to={`/blog?label=${encodeURIComponent(label.toLowerCase().replaceAll(" ", "-"))}`}><div key={`label-${index}`} className="bg-leonblue py-1 px-4 text-white mr-2 mb-2 inline-block text-md font-thin lowercase">{label}</div></Link>
										})
									}
								</div>
								<Link to={`/blog`}><p className="text-leonslate transition-all opacity-50 hover:opacity-100 mb-12 md:mb-0">Terug naar blogoverzicht</p></Link>
							</div>
							<div className="col-span-1 text-left">
								<div className="bg-white shadow mb-8 p-6 pb-4">
									<h3 className="uppercase text-xl mb-4">Onderwerpen</h3>
									{
										this.state.labels.map((label, index) => {
											return <Link to={`/blog?label=${encodeURIComponent(label.toLowerCase().replaceAll(" ", "-"))}`}><div key={`label-${index}`} className="bg-leonblue py-1 px-4 text-white mr-2 mb-2 inline-block text-md font-thin lowercase">{label}</div></Link>
										})
									}
								</div>
								<div className="bg-white shadow mb-8 p-6 pb-4">
									<h3 className="uppercase text-xl">Blog archief</h3>
									{
										[...postArchive.sort(() => 0.5 - Math.random()).slice(0,this.state.postArchiveAmount)].map((post, index) => {
											return (
												<div key={`archive-${index}`}>
													<p className="text-xs mt-4">Geschreven op {new Date(post.published).toLocaleDateString('nl-NL', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
													<Link to={`/blog/${encodeURIComponent(post.title.toLowerCase().replaceAll(" ", "-"))}`}>
														<p className="transition-all hover:text-leonblue mb-2 cursor-pointer font-bold uppercase">{post.title}</p>
													</Link>
												</div>
											)
										})
									}
								</div>
								<div className="bg-white shadow mb-4 p-6">
									<h3 className="uppercase text-xl">Delen</h3>
									<p className="my-4">Deel deze pagina via:</p>
									<a className="transition-all rounded-full w-6 h-6 border-2 border-[#1f1e21] hover:text-leonblue hover:border-leonblue relative inline-block mr-2" href={`https://www.facebook.com/sharer.php?u=${window.location.href}`} target="_blank" rel="noreferrer">
										<FontAwesomeIcon icon={["fa-brands", "facebook-f"]} fixedWidth size="xl" transform={"shrink-8"} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center"/>
									</a>
									<a className="transition-all rounded-full w-6 h-6 border-2 border-[#1f1e21] hover:text-leonblue hover:border-leonblue relative inline-block mr-2" href={`https://twitter.com/intent/tweet?url=${window.location.href}&text=leonadem - ${this.state.post.title}`} target="_blank" rel="noreferrer">
										<FontAwesomeIcon icon={["fa-brands", "twitter"]} fixedWidth size="xl" transform={"shrink-8"} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center"/>
									</a>
									<a className="transition-all rounded-full w-6 h-6 border-2 border-[#1f1e21] hover:text-leonblue hover:border-leonblue relative inline-block" href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`} target="_blank" rel="noreferrer">
										<FontAwesomeIcon icon={["fa-brands", "linkedin-in"]} fixedWidth size="xl" transform={"shrink-8"} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center"/>
									</a>
								</div>
							</div>
						</div>
					</div>
				</Container>
				<Footer pages={this.state.pages} currentPage="blog"/>
			</>
		);
	}
}

export default Post;