import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Jumbotron from "../components/Jumbotron";
import Divider from "../components/Divider";
import Container from "../components/Container";
import Footer from "../components/Footer";
import Text from "../components/Text";
import BlogImage from "../img/foto_blog.jpg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fab);
library.add(fas);
library.add(far);

class Blog extends React.Component {
	constructor(props) {
        super(props);

        this.state = {
            pages: this.props.pages,
			posts: [],
			pagePosts: [],
			labels: [],
			postsAmount: 5,
			currentPage: 0,
			postArchiveAmount: 5,
			currentLabel: new URLSearchParams(window.location.search).get("label")
        }
    }

	handleClickPage(pageNr) {
        this.setState({
			currentPage: pageNr,
			pagePosts: this.state.posts.filter((post) => { return this.state.currentLabel === null ? post.labels === undefined ? true : ["<ervaring>", "<ervaring-home>"].every(label => !post.labels.includes(label)) : post.labels === undefined ? false : post.labels.map(label => encodeURIComponent(label.toLowerCase().replaceAll(" ", "-"))).includes(encodeURIComponent(this.state.currentLabel.toLowerCase().replaceAll(" ", "-"))) }).slice(pageNr * this.state.postsAmount, pageNr * this.state.postsAmount + this.state.postsAmount)
		});

		this.scrollToPostsTop(); 
    }

	handleClickLabel(label) {
		var newLabel = this.state.currentLabel !== (label !== null ? label : "").toLowerCase().replaceAll(" ", "-") ? label.toLowerCase().replaceAll(" ", "-") : null;

		this.setState({
			currentLabel: newLabel,
			currentPage: 0,
			pagePosts: this.state.posts.filter((post) => { return newLabel === null ? post.labels === undefined ? true : ["<ervaring>", "<ervaring-home>"].every(label => !post.labels.includes(label)) : post.labels === undefined ? false : post.labels.map(label => encodeURIComponent(label.toLowerCase().replaceAll(" ", "-"))).includes(encodeURIComponent(newLabel.toLowerCase().replaceAll(" ", "-"))) }).slice(0 * this.state.postsAmount, 0 * this.state.postsAmount + this.state.postsAmount)
		});
	}

	scrollToPostsTop = () => {
        this.postsTop.scrollIntoView({ behavior: "smooth" });
    }

	componentDidMount() {
		window.scrollTo(0, 0);

		fetch(`https://www.googleapis.com/blogger/v3/blogs/1121658776827004251/posts?orderBy=published&fetchImages=true&maxResults=500&key=AIzaSyBm7KIjfVCs1NOpoUaJDB7xOoGTIsoYl28`)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    posts: res.items.filter((post) => { return post.labels !== undefined ? ["<ervaring>", "<ervaring-home>"].every(label => !post.labels.includes(label)) : true }),
					pagePosts: res.items.filter((post) => { return this.state.currentLabel === null ? post.labels === undefined ? true : ["<ervaring>", "<ervaring-home>"].every(label => !post.labels.includes(label)) : post.labels === undefined ? false : post.labels.map(label => encodeURIComponent(label.toLowerCase().replaceAll(" ", "-"))).includes(encodeURIComponent(this.state.currentLabel.toLowerCase().replaceAll(" ", "-"))) }).slice(this.state.currentPage * this.state.postsAmount, this.state.currentPage * this.state.postsAmount + this.state.postsAmount),
					labels: [...new Set(res.items.map((post) => { return post.labels }).flat())].filter(item => ![undefined, "<ervaring>", "<ervaring-home>", "<quote>"].includes(item))
                });
            });
	}

    render() {
		var pageLimit = Math.floor(this.state.posts.filter((post) => { return this.state.currentLabel === null ? post.labels === undefined ? true : ["<ervaring>", "<ervaring-home>"].every(label => !post.labels.includes(label)) : post.labels === undefined ? false : post.labels.map(label => encodeURIComponent(label.toLowerCase().replaceAll(" ", "-"))).includes(encodeURIComponent(this.state.currentLabel.toLowerCase().replaceAll(" ", "-"))) }).length / this.state.postsAmount) + (this.state.posts.filter((post) => { return this.state.currentLabel === null ? post.labels === undefined ? true : ["<ervaring>", "<ervaring-home>"].every(label => !post.labels.includes(label)) : post.labels === undefined ? false : post.labels.map(label => encodeURIComponent(label.toLowerCase().replaceAll(" ", "-"))).includes(encodeURIComponent(this.state.currentLabel.toLowerCase().replaceAll(" ", "-"))) }).length % this.state.postsAmount !== 0 ? 1 : 0);
		var pageNrs = [];
		for(let i = 0; i < pageLimit; i++) {
			pageNrs.push(i + 1);
		}

		var postArchive = this.state.posts.map((post) => {
			if(post.labels === undefined) {
				return post;
			} else if((!post.labels.includes("<quote>")) && (["<ervaring>", "<ervaring-home>"].every(label => !post.labels.includes(label)))) {
				return post;
			} else {
				return undefined;
			}
		}).filter((post) => post !== undefined);

		return (
			<>
				<Helmet>
					<title>leonadem - Blog</title>
					<meta property="og:title" content="leonadem - Blog"/>
					<meta property="og:image" content={new URL(BlogImage, document.baseURI).href}/>
					<meta property="og:url" content={window.location.href}/>
					<meta property="twitter:title" content="leonadem - Blog"/>
					<meta property="twitter:image" content={new URL(BlogImage, document.baseURI).href}/>
				</Helmet>
				<Jumbotron title="Blog" img={BlogImage}/>
				<Divider/>
				<Container>
					<Text pageId="3809448481023981500"/>
					<div className="mb-24 pt-0 px-0 md:px-20 text-center">
						<div style={{ float:"left", clear: "both" }}
							ref={(el) => { this.postsTop = el; }}>
						</div>
						<div className="grid grid-cols-1 md:grid-cols-3 gap-0 md:gap-8 mb-8 px-4 md:px-0">
							<div className="columns-1 md:columns-2 gap-8 col-span-2">
								{
									this.state.pagePosts.map((post, index) => {
										if((post.labels === undefined) || (!post.labels.includes("<quote>"))) {
											return (
												<div key={`post-${index}`} className="bg-white shadow mb-8 break-inside-avoid">
													{
														post.images !== undefined && <Link to={`/blog/${encodeURIComponent(post.title.toLowerCase().replaceAll(" ", "-"))}`}><img src={post.images[0].url} alt={`post-img`} className="object-cover aspect-video w-full hover:brightness-150 hover:contrast-50 transition-all"/></Link>
													}
													<div className="p-6 text-left">
														<h2 className="text-xl mb-4 font-bold uppercase">{post.title}</h2>
														<div dangerouslySetInnerHTML={{__html: post.content.replaceAll("<br />", "").replaceAll(`<p style="text-align: left;"></p>`, "").replaceAll("<p></p>", "").replaceAll(` style="clear: both; text-align: left;"`, "").replaceAll(` style="text-align: left;"`, "").match(/<p(.*?)<\/p>/g).map(function(val){ return val.replace(/<\/?p>/g,"")})[0].slice(0, 200) + "..." } }/>
														<Link to={`/blog/${encodeURIComponent(post.title.toLowerCase().replaceAll(" ", "-"))}`}>
															<p className="transition-all text-leonblue hover:text-leonblue-dark mt-4 underline underline-offset-2 cursor-pointer font-bold">Lees meer ...</p>
														</Link>
														<p className="text-xs mb-0 mt-8">Geschreven op {new Date(post.published).toLocaleDateString('nl-NL', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
													</div>
												</div>
											)
										} else {
											return (
												<div key={`post-${index}`} className="bg-leonslate shadow mb-8 mx-0 text-center py-8 px-12 text-white text-xl h-max-min break-inside-avoid">
													{post.title}
												</div>
											)
										}
									})
								}
								<div className="mt-8 mb-16 block md:hidden">
									{
										pageNrs.map((pageNr, index) => {
											return (
												<button key={`post-btn-${index}`} className={`w-10 h-10 mx-1 mb-2 transition-all rounded-full shadow hover:bg-white font-thin ${index === this.state.currentPage ? "bg-white" : "bg-gray-200 text-gray-400 hover:text-[#1f1e21]"}`} onClick={() => {this.handleClickPage(index)}}>{pageNr}</button>
											)
										})
									}
								</div>
							</div>
							<div className="col-span-1 text-left">
								<div className="bg-white shadow mb-8 p-6 pb-4">
									<h3 className="uppercase text-xl mb-4">Onderwerpen</h3>
									{
										this.state.labels.map((label, index) => {
											return <Link key={`label-${index}`} to={`/blog?label=${encodeURIComponent(label.toLowerCase().replaceAll(" ", "-"))}`}><div className={`bg-leonblue py-1 px-4 text-white mr-2 mb-2 inline-block text-md font-thin lowercase ${this.state.currentLabel !== null ? this.state.currentLabel.toLowerCase().replaceAll(" ", "-") !== encodeURIComponent(label.toLowerCase().replaceAll(" ", "-")) ? "opacity-30" : "" : ""}`} onClick={() => {this.handleClickLabel(label)}}>{label}</div></Link>
										})
									}
								</div>
								<div className="bg-white shadow mb-8 p-6 pb-4">
									<h3 className="uppercase text-xl">Blog archief</h3>
									{
										[...postArchive.sort(() => 0.5 - Math.random()).slice(0,this.state.postArchiveAmount)].map((post, index) => {
											return (
												<div key={`archive-${index}`}>
													<p className="text-xs mt-4">Geschreven op {new Date(post.published).toLocaleDateString('nl-NL', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
													<Link to={`/blog/${encodeURIComponent(post.title.toLowerCase().replaceAll(" ", "-"))}`}>
														<p className="transition-all hover:text-leonblue mb-2 cursor-pointer font-bold uppercase">{post.title}</p>
													</Link>
												</div>
											)
										})
									}
								</div>
								<div className="bg-white shadow mb-8 p-6">
									<h3 className="uppercase text-xl">Delen</h3>
									<p className="my-4">Deel deze pagina via:</p>
									<a className="transition-all rounded-full w-8 h-8 border-2 border-[#1f1e21] hover:text-leonblue hover:border-leonblue relative inline-block mr-2" href={`https://www.facebook.com/sharer.php?u=${window.location.href}`} target="_blank" rel="noreferrer">
										<FontAwesomeIcon icon={["fa-brands", "facebook-f"]} fixedWidth size="xl" transform={"shrink-8"} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center"/>
									</a>
									<a className="transition-all rounded-full w-8 h-8 border-2 border-[#1f1e21] hover:text-leonblue hover:border-leonblue relative inline-block mr-2" href={`https://twitter.com/intent/tweet?url=${window.location.href}`} target="_blank" rel="noreferrer">
										<FontAwesomeIcon icon={["fa-brands", "twitter"]} fixedWidth size="xl" transform={"shrink-8"} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center"/>
									</a>
									<a className="transition-all rounded-full w-8 h-8 border-2 border-[#1f1e21] hover:text-leonblue hover:border-leonblue relative inline-block" href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`} target="_blank" rel="noreferrer">
										<FontAwesomeIcon icon={["fa-brands", "linkedin-in"]} fixedWidth size="xl" transform={"shrink-8"} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center"/>
									</a>
								</div>
							</div>
						</div>
						<div className="mt-8 mb-16 hidden md:block">
							{
								pageNrs.map((pageNr, index) => {
									return (
										<button key={`post-btn-${index}`} className={`w-10 h-10 mx-1 mb-2 transition-all rounded-full shadow hover:bg-white font-thin ${index === this.state.currentPage ? "bg-white" : "bg-gray-200 text-gray-400 hover:text-[#1f1e21]"}`} onClick={() => {this.handleClickPage(index)}}>{pageNr}</button>
									)
								})
							}
						</div>
					</div>
				</Container>
				<Footer pages={this.state.pages} currentPage="blog"/>
			</>
		);
	}
}

export default Blog;