import React from "react";
import { Helmet } from "react-helmet";

import Jumbotron from "../components/Jumbotron";
import Divider from "../components/Divider";
import Container from "../components/Container";
import Footer from "../components/Footer";
import Text from "../components/Text";
import ContactImage from "../img/foto_contact.jpg";

class Contact extends React.Component {
	constructor(props) {
        super(props);

        this.state = {
            pages: this.props.pages
        }
    }

	componentDidMount() {
		window.scrollTo(0, 0);
	}

    render() {
		return (
			<>
				<Helmet>
					<title>leonadem - Contact</title>
					<meta property="og:title" content="leonadem - Contact"/>
					<meta property="og:image" content={new URL(ContactImage, document.baseURI).href}/>
					<meta property="og:url" content={window.location.href}/>
					<meta property="twitter:title" content="leonadem - Contact"/>
					<meta property="twitter:image" content={new URL(ContactImage, document.baseURI).href}/>
				</Helmet>
				<Jumbotron title="Contact" img={ContactImage}/>
				<Divider/>
				<Container>
					<Text align="center" pageId="5485816008582239669"/>
				</Container>
				<Footer pages={this.state.pages} currentPage="contact"/>
			</>
		);
	}
}

export default Contact;