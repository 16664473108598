import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Jumbotron from "../components/Jumbotron";
import Divider from "../components/Divider";
import Container from "../components/Container";
import Emphasize from "../components/Emphasize";
import Footer from "../components/Footer";
import Text from "../components/Text";
import HelendeAdemImage from "../img/foto_helendeadem.jpg";

class HelendeAdem extends React.Component {
	constructor(props) {
        super(props);

        this.state = {
            pages: this.props.pages
        }
    }

	componentDidMount() {
		window.scrollTo(0, 0);
	}

    render() {
		return (
			<>
				<Helmet>
					<title>leonadem - Helende Adem</title>
					<meta property="og:title" content="leonadem - Helende Adem"/>
					<meta property="og:image" content={new URL(HelendeAdemImage, document.baseURI).href}/>
					<meta property="og:url" content={window.location.href}/>
					<meta property="twitter:title" content="leonadem - Helende Adem"/>
					<meta property="twitter:image" content={new URL(HelendeAdemImage, document.baseURI).href}/>
				</Helmet>
				<Jumbotron title="Helende adem" img={HelendeAdemImage}/>
				<Divider/>
				<Container>
					<Text align="left" pageId="680558900058325244"/>
				</Container>
				<Emphasize pageId="1523459944599775852"/>
				<Container>
					<Text pageId="5142095470910726880"/>
					<div className="pb-16 px-8 w-full md:pl-20">
						<Link to="/contact">
							<button className="transition-all text-lg font-normal bg-leonblue hover:bg-leonslate hover:scale-105 text-white py-3 px-6 underline underline-offset-2 cursor-pointer w-full md:w-fit">
								Plan een sessie
							</button>
						</Link>
					</div>
				</Container>
				<Footer pages={this.state.pages} currentPage="helende-adem"/>
			</>
		);
	}
}

export default HelendeAdem;