import Container from "./Container";
import React from "react";

export class Reviews extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reviews: [
                {
                    content: "",
                    title: ""
                }
            ],
            currentReview: 0,
            intervalTime: 12250,
            hideTime: 250,
            showReview: true
        }
    }

    onFocus = () => {
        var newReview = this.state.reviews.length - 1 === this.state.currentReview ? 0 : this.state.currentReview + 1;
        this.setState({
            currentReview: newReview
        });
    }

    componentDidMount() {
        window.addEventListener("focus", this.onFocus); 

        fetch(`https://www.googleapis.com/blogger/v3/blogs/1121658776827004251/posts?orderBy=published&maxResults=500&key=AIzaSyBm7KIjfVCs1NOpoUaJDB7xOoGTIsoYl28`)
            .then(res => res.json())
            .then(res => {

                var ervaringenItems = res.items.filter(item => {
					return item.labels !== undefined && item.labels.includes("<ervaring-home>");
				});

				this.setState({
                    reviews: ervaringenItems,
                });
            });

        setTimeout(() => {
            this.setState({
                showReview: false
            });
        }, this.state.intervalTime - this.state.hideTime);
        
        setInterval(() => {
            this.setState({
                currentReview: this.state.reviews.length - 1 === this.state.currentReview ? 0 : this.state.currentReview + 1,
                showReview: true
            });
            setTimeout(() => {
                this.setState({
                    showReview: false
                });
            }, this.state.intervalTime - this.state.hideTime);
        }, this.state.intervalTime);
    }

    render() {
        return (
            <div className="bg-leonslate">
                <Container>
                    <div className="relative py-16 text-center text-white h-[40rem] md:h-[28rem] w-full">
                        <div className="absolute top-1/2 transform -translate-y-1/2 text-center w-full pt-8 pb-16 px-4 md:px-16">
                            <div className={`transition-all w-full ${ this.state.showReview ? "opacity-100" : "opacity-0" }`}>
                                <div className="text-md md:text-lg font-thin !leading-relaxed" dangerouslySetInnerHTML={{__html: this.state.reviews[this.state.currentReview].content }}></div>
                                <p className="uppercase font-bold mt-6 w-full">{ this.state.reviews[this.state.currentReview].title }</p>
                            </div>
                        </div>
                        <div className="absolute bottom-0 pb-24 w-full">
                                {
                                    this.state.reviews.map((review, index) => {
                                        return <div key={`review-${index}`} className={`transition-all inline-block h-3 w-3 rounded-full mx-2 ${ this.state.currentReview === index ? "bg-leonblue" : "bg-leonslate-dark"}`} onClick={ () => { this.setState({ currentReview: index }) } }></div>
                                    })
                                }
                            </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default Reviews;