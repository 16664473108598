import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import ErrorImage from "../img/foto_menu.jpg";

class Error extends React.Component {
	constructor(props) {
        super(props);

        this.state = {
            pages: this.props.pages
        }
    }

	componentDidMount() {
		window.scrollTo(0, 0);
	}

    render() {
		return (
			<>
				<Helmet>
					<title>leonadem - Error</title>
				</Helmet>
                <div className="absolute top-0 left-0 w-screen h-screen overflow-y-hidden bg-leonslate bg-center bg-cover bg" style={{backgroundImage: `url('${ErrorImage}')`}}>
                    <div className="relative w-full h-full">
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                            <div className="text-white uppercase mb-8 max-w-[800px]">
                                <p className="mb-4 font-medium text-sm sm:text-md md:text-xl">Oeps ... deze pagina bestaat niet!</p>
                                <p className="mb-4 font-medium text-sm sm:text-md md:text-xl">Helaas is de pagina die je zoekt niet te vinden. Dit kan komen omdat de pagina niet meer bestaat, of doordat er een typefout is gemaakt bij het invoeren van het adres.</p>
                                <p className="mb-8 font-medium text-sm sm:text-md md:text-xl">Kan gebeuren</p>
                                <h1 className="mb-8 text-leonblue text-2xl sm:text-5xl md:text-7xl font-black uppercase">Adem in adem uit ;-)</h1>
                                <Link to="/">
                                    <button className="transition-all text-lg font-normal bg-leonblue hover:bg-leonslate hover:scale-105 text-white py-3 px-6 mt-0 mb-6 underline underline-offset-2 cursor-pointer w-full md:w-fit">
                                        Plan een sessie
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
			</>
		);
	}
}

export default Error;