import React from "react";

export class Container extends React.Component {
    render() {
        return (
            <div className="mx-auto max-w-[1300px] text-lg">
                {this.props.children}
            </div>
        );
    }
}

export default Container;