import React from "react";
import { Link } from "react-router-dom";

export class Location extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: null,
            img: this.props.img
        }
    }

    componentDidMount() {
        fetch(`https://www.googleapis.com/blogger/v3/blogs/1121658776827004251/pages/${this.props.pageId}?key=AIzaSyBm7KIjfVCs1NOpoUaJDB7xOoGTIsoYl28`)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    content: res.content
                });
            });
    }

    render() {
        return (
            <div className="grid grid-cols-12 py-0 mx-12 md:mx-8 md:px-12 pb-8 md:pb-12">
                <img className="col-span-12 md:col-span-2 w-full bg-red-400" src={this.state.img}/>
                {
                    this.state.content === null ? (
                        <div className="col-span-12 md:col-span-10 px-8 animate-pulse">
                            <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 mb-6"/>
                            <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 mb-6"/>
                            <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 mb-6"/>
                            <div className="h-2 bg-slate-200 rounded rounded-full col-span-2 w-[60%]"/>
                        </div>
                    ) : (
                        <>
                            <div className="col-span-12 md:col-span-10 pt-8 md:pt-0 mb-8 md:mb-0 md:px-8">
                                <div className="page-content location-content" dangerouslySetInnerHTML={{__html: this.state.content }}/>
                                <Link to="/contact">
                                    <button className="transition-all text-lg font-normal bg-leonblue hover:bg-leonslate hover:scale-105 text-white mt-6 py-3 px-6 underline underline-offset-2 cursor-pointer w-full md:w-fit">
                                        Plan een sessie
                                    </button>
                                </Link>
                            </div>
                        </>
                    )
                }
            </div>
        );
    }
}

export default Location;