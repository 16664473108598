import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Jumbotron from "../components/Jumbotron";
import Divider from "../components/Divider";
import Container from "../components/Container";
import Reviews from "../components/Reviews";
import Footer from "../components/Footer";
import Text from "../components/Text";
import IndexImage from "../img/foto_home.jpg";
import SideEffects from "../components/SideEffects";

class Index extends React.Component {
	constructor(props) {
        super(props);

        this.state = {
            reviews: [
				{
					text: "Cavia's (Cavia) of Guinese biggetjes zijn een geslacht van de Zuid-Amerikaanse knaagdieren uit de familie Caviidae. Ze worden in bepaalde Zuid-Amerikaanse landen als voedseldier gefokt. In de meeste andere landen zijn ze tamelijk populaire, makkelijk te houden huisdieren.",
					name: "Cas Cavia"
				}, {
					text: "Hagedissen (Lacertilia) vormen een onderorde van de schubreptielen (Squamata), die meer dan 7100 soorten telt. Hagedissen zijn daardoor de grootste groep van alle moderne reptielen. Ze zijn ontstaan in het Trias, maar goede fossielen zijn pas bekend uit het Jura. De slangen ontstonden in het Krijt uit een groep van de hagedissen.",
					name: "Harrie Hagedis"
				}, {
					text: "De reuzenpanda, ook wel panda, pandabeer of bamboebeer (Ailuropoda melanoleuca) is een zoogdier dat behoort tot de beren (Ursidae).",
					name: "Pjotr Panda"
				}
			],
			pages: this.props.pages
        }
    }

	componentDidMount() {
		window.scrollTo(0, 0);
	}

    render() {
		return (
			<>
				<Helmet>
					<title>leonadem</title>
					<meta property="og:title" content="leonadem"/>
					<meta property="og:image" content={new URL(IndexImage, document.baseURI).href}/>
					<meta property="og:url" content={window.location.href}/>
					<meta property="twitter:title" content="leonadem"/>
					<meta property="twitter:image" content={new URL(IndexImage, document.baseURI).href}/>
				</Helmet>
				<Jumbotron img={IndexImage} logo/>
				<Divider/>
				<Container>
					<Text align="left" pageId="1719432421351293032"/>
				</Container>
				<Reviews reviews={this.state.reviews}/>
				<Divider/>
				<SideEffects amount={5}/>
				<div className="text-center p-8">
					<Link to="/positieve-bijwerkingen">
						<button className="transition-all text-lg font-normal bg-leonblue hover:bg-leonslate hover:scale-105 text-white py-3 px-6 mt-0 mb-6 underline underline-offset-2 cursor-pointer w-full md:w-fit">
							Meer positieve bijwerkingen
						</button>
					</Link>
                </div>
				<Footer pages={this.state.pages} currentPage="index"/>
			</>
		);
	}
}

export default Index;