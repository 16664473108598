import React from "react";
import { Helmet } from "react-helmet";

import Jumbotron from "../components/Jumbotron";
import Divider from "../components/Divider";
import Container from "../components/Container";
import Footer from "../components/Footer";
import ErvaringenImage from "../img/foto_ervaringen.jpg";

class Ervaringen extends React.Component {
	constructor(props) {
        super(props);

        this.state = {
            pages: this.props.pages,
			reviews: [],
			pageReviews: [],
			reviewsAmount: 3,
			currentPage: 0
        }
    }

	handleClick(pageNr) {
        this.setState({
			currentPage: pageNr,
			pageReviews: this.state.reviews.slice(pageNr * this.state.reviewsAmount, pageNr * this.state.reviewsAmount + this.state.reviewsAmount)
		});

		this.scrollToReviewsTop(); 
    }

	scrollToReviewsTop = () => {
        this.reviewsTop.scrollIntoView({ behavior: "smooth" });
    }

	componentDidMount() {
		window.scrollTo(0, 0);

		fetch(`https://www.googleapis.com/blogger/v3/blogs/1121658776827004251/posts?orderBy=published&maxResults=500&key=AIzaSyBm7KIjfVCs1NOpoUaJDB7xOoGTIsoYl28`)
            .then(res => res.json())
            .then(res => {
				var ervaringenItems = res.items.filter(item => {
					return item.labels !== undefined && item.labels.includes("<ervaring>");
				});

				this.setState({
                    reviews: ervaringenItems,
					pageReviews: ervaringenItems.slice(this.state.currentPage * this.state.reviewsAmount, this.state.currentPage * this.state.reviewsAmount + this.state.reviewsAmount)
                });
            });
	}

    render() {
		var pageLimit = Math.floor(this.state.reviews.length / this.state.reviewsAmount) + (this.state.reviews.length % this.state.reviewsAmount !== 0 ? 1 : 0);
		var pageNrs = [];
		for(let i = 0; i < pageLimit; i++) {
			pageNrs.push(i + 1);
		}

		return (
			<>
				<Helmet>
					<title>leonadem - Ervaringen</title>
					<meta property="og:title" content="leonadem - Ervaringen"/>
					<meta property="og:image" content={new URL(ErvaringenImage, document.baseURI).href}/>
					<meta property="og:url" content={window.location.href}/>
					<meta property="twitter:title" content="leonadem - Ervaringen"/>
					<meta property="twitter:image" content={new URL(ErvaringenImage, document.baseURI).href}/>
				</Helmet>
				<Jumbotron title="Ervaringen" img={ErvaringenImage}/>
				<Divider/>
				<Container>
					<div className="mb-24 pt-8 px-0 md:px-8 text-center">
						<div style={{ float:"left", clear: "both" }}
							ref={(el) => { this.reviewsTop = el; }}>
						</div>
						{
							this.state.pageReviews.map((review, index) => {
								return (
									<div key={`review-${index}`} className="page-content bg-white shadow p-12 mb-8 text-left">
										<div dangerouslySetInnerHTML={{__html: review.content }}/>
										<p className="uppercase text-leonblue mt-10 font-bold">- {review.title}</p>
									</div>
								)
							})
						}
						{
							pageNrs.map((pageNr, index) => {
								return (
									<button key={`review-btn-${index}`} className={`w-10 h-10 mx-1 mb-2 transition-all rounded-full shadow hover:bg-white font-thin ${index === this.state.currentPage ? "bg-white" : "bg-gray-200 text-gray-400 hover:text-[#1f1e21]"}`} onClick={() => {this.handleClick(index)}}>{pageNr}</button>
								)
							})
						}
					</div>
				</Container>
				<Footer pages={this.state.pages} currentPage="ervaringen"/>
			</>
		);
	}
}

export default Ervaringen;