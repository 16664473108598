import React from "react";
import { Helmet } from "react-helmet";

import Jumbotron from "../components/Jumbotron";
import Divider from "../components/Divider";
import Container from "../components/Container";
import Emphasize from "../components/Emphasize";
import Footer from "../components/Footer";
import Text from "../components/Text";
import Location from "../components/Location";
import LocatieImage from "../img/foto_locatie.jpg";
import LocatieLoftBoksumImage from "../img/loft_boksum.jpg";
import LocatieYogaCentrumLeeuwardenImage from "../img/locatie_yogascentrumleeuwarden.jpg";

class Locatie extends React.Component {
	constructor(props) {
        super(props);

        this.state = {
            pages: this.props.pages
        }
    }

	componentDidMount() {
		window.scrollTo(0, 0);
	}

    render() {
		return (
			<>
				<Helmet>
					<title>leonadem - Locatie</title>
					<meta property="og:title" content="leonadem - Locatie"/>
					<meta property="og:image" content={new URL(LocatieImage, document.baseURI).href}/>
					<meta property="og:url" content={window.location.href}/>
					<meta property="twitter:title" content="leonadem - Locatie"/>
					<meta property="twitter:image" content={new URL(LocatieImage, document.baseURI).href}/>
				</Helmet>
				<Jumbotron title="Locatie" img={LocatieImage}/>
				<Divider/>
				<Container>
                    {/* <h1 className="mx-12 pt-12 md:mx-20 text-xl md:text-4xl font-medium uppercase">Tarieven { new Date().getFullYear() }</h1>
                    <Text pageId="7397357921418424701" marginTop={0}/> */}
                    <h1 className="mx-12 pt-12 md:mx-20 text-xl md:text-4xl font-medium uppercase mb-6">Locatie 2022/2023</h1>
                    <p className="mx-12 md:mx-20 mb-6">Op dit moment geef ik ademsessies op de volgende locatie:</p>
                    {/* <Location img={LocatieLoftBoksumImage} pageId="5102633270724902186"/> */}
                    <Location img={LocatieYogaCentrumLeeuwardenImage} pageId="4695274121440634022"/>
					<div className="block h-12"/>
				</Container>
				<Emphasize pageId="4052815953374402911"/>
				<Container>
                    <Text pageId="2476141499497206543"/>
				</Container>
				<Footer pages={this.state.pages} currentPage="locatie"/>
			</>
		);
	}
}

export default Locatie;