import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Jumbotron from "../components/Jumbotron";
import Divider from "../components/Divider";
import Container from "../components/Container";
import Emphasize from "../components/Emphasize";
import Footer from "../components/Footer";
import Text from "../components/Text";
import AdemsessiesImage from "../img/foto_ademsessies.jpg";

class Ademsessies extends React.Component {
	constructor(props) {
        super(props);

        this.state = {
            pages: this.props.pages
        }
    }

	componentDidMount() {
		window.scrollTo(0, 0);
	}

    render() {
		return (
			<>
				<Helmet>
					<title>leonadem - Ademsessies</title>
					<meta property="og:title" content="leonadem - Ademsessies"/>
					<meta property="og:image" content={new URL(AdemsessiesImage, document.baseURI).href}/>
					<meta property="og:url" content={window.location.href}/>
					<meta property="twitter:title" content="leonadem - Ademsessies"/>
					<meta property="twitter:image" content={new URL(AdemsessiesImage, document.baseURI).href}/>
				</Helmet>
				<Jumbotron title="Ademsessies" img={AdemsessiesImage}/>
				<Divider/>
				<Container>
					<Text align="left" pageId="1708208604328169130"/>
                    <Text pageId="268181256670403473"/>
                    <div className="pb-16 px-8 w-full md:pl-20">
						<Link to="/contact">
							<button className="transition-all text-lg font-normal bg-leonblue hover:bg-leonslate hover:scale-105 text-white py-3 px-6 underline underline-offset-2 cursor-pointer w-full md:w-fit">
								Plan een sessie
							</button>
						</Link>
					</div>
				</Container>
				<Emphasize pageId="820977932093619927"/>
				<Container>
					<Text pageId="3930628549817925602"/>
					<div className="pb-16 px-8 w-full md:pl-20">
						<Link to="/contact">
							<button className="transition-all text-lg font-normal bg-leonblue hover:bg-leonslate hover:scale-105 text-white py-3 px-6 underline underline-offset-2 cursor-pointer w-full md:w-fit">
								Vraag naar de mogelijkheden
							</button>
						</Link>
					</div>
				</Container>
				<Footer pages={this.state.pages} currentPage="ademsessies"/>
			</>
		);
	}
}

export default Ademsessies;